import { defineComponent, ref, reactive, toRefs, onMounted, watch, nextTick, inject } from "vue";
import { basicMixin } from "@/mixin/basicMixin";
import { ArrowRight } from "@element-plus/icons-vue";
import { imgUrl } from "@/utils/urlManage";
import { ElMessage } from "element-plus";
import { getProductNav } from "@/http/service";
import { useRoute, useRouter } from "vue-router";
import emitter from "@/mitt";
import { getPart } from "@/http/home";
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      // 产品数据
      productDeatil: [],
      // 侧边栏数据
      menuList: [],
      pathList: [{
        title: "服务支持",
        path: "#/service",
        first: ""
      }],
      onlyDetail: {},
      oneName: "",
      num: 0,
      orgCode: sessionStorage.getItem("orgCode") || "",
      childCode: "",
      falg: false,
      partDate: {}
    });

    const _this = reactive({
      defaultKeys: [],
      defaultNode: ""
    });

    const trees = ref();
    const methods = {
      //重置面包屑
      resertPath() {
        data.pathList = [{
          title: "服务支持",
          path: "#/service",
          first: ""
        }];
      },

      getProductNavMethod() {
        // 获取服务支持侧边栏
        getProductNav({
          orgCode: data.orgCode
        }).then(res => {
          if (res.data.code == 200) {
            data.menuList = res.data.data;

            if (route.query.code) {
              _this.defaultKeys = route.query.code;
              _this.defaultNode = route.query.code;
            } else {
              _this.defaultKeys = [res.data.data[0].code];
              _this.defaultNode = res.data.data[0].code;
            }

            if (!route.query.code) {
              methods.changeMeauOne(res.data.data[0]);
            } else {
              methods.changMeau();
            }
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      //进入页面判断
      changMeau() {
        data.menuList.forEach((item, i) => {
          if (item.code == route.query.code) {
            methods.changeMeauOne(item); // methods.changeKeys(item.code as string)
          } else {
            var _item$children;

            (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.forEach(ico => {
              if (ico.code == route.query.code) {
                methods.changeMeauTwo(ico, item);
                methods.changeKeys(ico.code);
              }
            });
          }
        });
      },

      /**
       * 点击头部面包屑跳转
       * @param title
       */
      async changeBread(title) {
        if (title.title == "服务支持") {
          methods.changeMeauOne(data.menuList[0]);
          let timer = setTimeout(() => {
            location.reload();
            clearTimeout(timer);
          });
        } else if (title.first == '1') {
          await router.push(title.path);
          location.reload();
        }
      },

      /**
       * 点击一级修改右侧数据
       * @param str
       */
      changeMeauOne(str) {
        data.productDeatil = []; //表头更改

        methods.resertPath();
        data.pathList.push({
          title: str.name,
          path: `/service?code=${str.code}`,
          first: str.first
        });
        data.falg = false;
        data.childCode = str.code;

        if (str.supportProductDetailList) {
          // str.supportProductDetailList.forEach((e:any)=>{
          //   data.productDeatil.push(e);
          // })
          data.productDeatil.push(...str.supportProductDetailList);
        }

        if (str.children) {
          data.productDeatil.push(...str.children);
        } else {
          data.productDeatil.push(str);
        } // data.productDeatil = []
        // data.num = str.id
        // if (str.children.length > 0) {
        //   data.productDeatil = str.children
        // } else {
        //   data.productDeatil.push(str)
        // }
        // console.log(str,data.productDeatil);
        // //表头更改
        // methods.resertPath()
        // data.pathList.push({
        //   title: str.name,
        //   path: `/service?id=${str.id}`
        // })
        // data.oneName = str.name
        // router.push({
        //   path: `#/service`,
        //   query: { id: str.id },
        // })

      },

      /**
       * 点击二级修改右侧数据
       * @param str
       */
      changeMeauTwo(str, oneStr) {
        console.log(str, oneStr);
        data.productDeatil = [];
        data.productDeatil.push(str);
        methods.resertPath();
        data.pathList.push({
          title: oneStr.name,
          first: oneStr.first,
          path: `/service?code=${oneStr.code}`
        });
        data.pathList.push({
          title: str.name,
          path: `/service?code=${str.code}`,
          first: str.first
        });
        data.childCode = oneStr.code; // router.push(`/product/detail/${str.code`);

        data.falg = true;
      },

      /**
       * 查看详情
       */
      async lookDetail(code, name) {
        data.pathList.push({
          title: name,
          path: "",
          first: ""
        });
        sessionStorage.setItem("deviceDetail", JSON.stringify(data.pathList));
        await router.push({
          path: `/deviceDetailsByCode?code=${code}`,
          query: {
            code: code
          }
        }); //  nextTick(() => {
        //   location.reload()
        // })
      },

      /**
       *
       *  节点点击事件
       * @param node 当前点击的节点
       */
      handleNodeClick(node, tree) {
        if (tree.level == 1) {
          methods.changeMeauOne(node);
        } else if (tree.level == 2) {
          methods.changeMeauTwo(node, tree.parent.data);
        }
      },

      /**+
       * 改变树的展开值，并合上其他节点
       * @param id 需要展开的节点的id
       */
      changeKeys(code) {
        if (code != _this.defaultKeys[0]) {
          var _trees$value$store;

          //  保证树形结构只有一个项目展开，先合上全部的节点
          let obj = (_trees$value$store = trees.value.store) === null || _trees$value$store === void 0 ? void 0 : _trees$value$store.nodesMap;

          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const element = obj[key];
              element.expanded = false;
            }
          } // 再重新赋值打开的节点值


          _this.defaultKeys = [code];
          _this.defaultNode = code;
        }
      },

      async getPartMethod() {
        const res = await getPart({
          code: "serviceSupport",
          orgCode: data.orgCode
        });

        if (res.data.code == 200) {
          data.partDate = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      }

    };
    onMounted(() => {
      methods.getProductNavMethod();
      methods.getPartMethod();
    });
    const reload = inject("reload");
    emitter.on("pro-getServiceMenu", () => {
      nextTick(() => {
        // location.reload()
        reload && reload();
      });
    }); // 监听路由变化[id]

    watch(() => route.query.code, (newValue, oldValue) => {
      if (newValue != "") {
        methods.changMeau(); // methods.changeKeys(newValue as string)
      } else {
        methods.changeMeauOne(data.menuList[0]);
      }
    });
    return { ...toRefs(data),
      ...toRefs(_this),
      ...methods,
      imgUrl,
      route,
      ArrowRight
    };
  }

});