import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1e6a1f79"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "top"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "english"
};
const _hoisted_4 = {
  key: 0,
  class: "left"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "custom-tree-node"
};
const _hoisted_7 = {
  class: "right"
};
const _hoisted_8 = {
  key: 0,
  class: "right"
};
const _hoisted_9 = {
  key: 0,
  class: "subClass-box"
};
const _hoisted_10 = {
  class: "title"
};
const _hoisted_11 = {
  class: "inner"
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  key: 1,
  class: "inner-box"
};
const _hoisted_14 = {
  key: 0,
  class: "title"
};
const _hoisted_15 = {
  class: "inner"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  key: 0
};
const _hoisted_18 = {
  class: "inner-box"
};
const _hoisted_19 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$pathList$2;

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_ArrowUp = _resolveComponent("ArrowUp");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_ArrowDown = _resolveComponent("ArrowDown");

  const _component_el_tree = _resolveComponent("el-tree");

  const _component_el_image = _resolveComponent("el-image");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
    separator: "#/service",
    "separator-icon": _ctx.ArrowRight
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pathList, item => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, null, {
        default: _withCtx(() => [_createElementVNode("span", {
          style: {
            "color": "#fff",
            "cursor": "pointer"
          },
          onClick: $event => _ctx.changeBread(item)
        }, _toDisplayString(item.title), 9, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 256))]),
    _: 1
  }, 8, ["separator-icon"])]), _createElementVNode("header", null, _toDisplayString(_ctx.partDate.name), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.partDate.subtitle), 1), _createElementVNode("main", null, [_ctx.menuList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.partDate.name), 1), _createVNode(_component_el_tree, {
    ref: "trees",
    data: _ctx.menuList,
    props: _ctx.defaultProps,
    "node-key": "code",
    accordion: "",
    "highlight-current": "",
    "current-node-key": _ctx.defaultNode,
    onNodeClick: _ctx.handleNodeClick,
    "default-expanded-keys": _ctx.defaultKeys
  }, {
    default: _withCtx(({
      node,
      data
    }) => [data.children && data.children.length ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [node.expanded ? (_openBlock(), _createBlock(_component_el_icon, {
      key: 0,
      class: "expand-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_ArrowUp)]),
      _: 1
    })) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      class: "expand-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_ArrowDown)]),
      _: 1
    }))], 64)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_6, _toDisplayString(data.name), 1)]),
    _: 1
  }, 8, ["data", "props", "current-node-key", "onNodeClick", "default-expanded-keys"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_ctx.productDeatil ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productDeatil, (item, index) => {
    var _ctx$pathList$;

    return _openBlock(), _createElementBlock("div", null, [item.first == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.supportProductDetailList, ico => {
      return _openBlock(), _createElementBlock("div", {
        onClick: $event => _ctx.lookDetail(ico.code, ico.name)
      }, [_createVNode(_component_el_image, {
        src: _ctx.imgUrl(ico.coverUrl),
        alt: "",
        lazy: "",
        fit: "cover"
      }, null, 8, ["src"]), _createElementVNode("p", null, _toDisplayString(ico.name), 1)], 8, _hoisted_12);
    }), 256))])])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [index < 3 ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(index == 0 ? (_ctx$pathList$ = _ctx.pathList[1]) === null || _ctx$pathList$ === void 0 ? void 0 : _ctx$pathList$.title : ' '), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", {
      onClick: $event => _ctx.lookDetail(item.code, item.name)
    }, [_createVNode(_component_el_image, {
      src: _ctx.imgUrl(item.coverUrl),
      alt: "",
      lazy: "",
      fit: "cover"
    }, null, 8, ["src"]), _createElementVNode("p", null, _toDisplayString(item.name), 1)], 8, _hoisted_16)])]))]);
  }), 256)), _ctx.productDeatil.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString((_ctx$pathList$2 = _ctx.pathList[1]) === null || _ctx$pathList$2 === void 0 ? void 0 : _ctx$pathList$2.title), 1)])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])])]);
}